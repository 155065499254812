<div *ngIf="pageTitle && pageTitle != ''" class="flex w-full pt-1">
    <div *ngIf="displayTitle" id="pageTitle" class="text-lg font-extrabold text-left w-full page-title"
        [ngClass]="{'pt-1': adjustTitle, 'pl-4': adjustTitle}">{{pageTitle}}</div>
    <div  [ngClass]="{'w-3/12': displayTitle, 'w-full': !displayTitle}" class="text-right mr-2">
        <i class="mdi mdi-close text-gray-700 text-xl cursor-pointer" *ngIf="displayCloseButton" (click)="onClose()"></i>
    </div>
</div>
<div>
    <router-outlet>
        <app-loading *ngIf="isLoading"></app-loading>
    </router-outlet>
</div>