import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectErrorCode } from './models/ConnectErrorCode';
import { EventListenerService } from './services/event-listener.service';
import { LoadingService } from './services/loading.service';
import { PageTitleService } from './services/page-title.service';
import { TermsConditionsService } from './services/terms-conditions.service';
import { TokenService } from './services/token.service';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  pageTitle?: string;
  isLoading: boolean = false;
  token?: string = '';
  theme?: string = undefined;
  displayCloseButton?: boolean = false;
  displayTitle?: boolean = false;
  adjustTitle?: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private tokenService: TokenService,
    private pageTitleService: PageTitleService,
    private eventListenerService: EventListenerService,
    private loadingService: LoadingService,
    private termsConditionsService: TermsConditionsService
  ) { }

  ngOnInit(): void {

    if (window.location.search === '') {
      this.goToError(ConnectErrorCode.TokenExpired);
    }

    this.route.queryParams.pipe(skip(1)).subscribe(params => {
      this.displayCloseButton = params['displayclosebutton'] ? JSON.parse(params['displayclosebutton']) : false;
      this.displayTitle = params['displaytitle'] ? JSON.parse(params['displaytitle']) : true;
      this.adjustTitle = params['adjusttitle'] ? JSON.parse(params['adjusttitle']) : false;
      this.token = params['token'] || undefined;

      if(this.theme == undefined){
        this.theme = params['theme'] || 'light';
      }

      document.getElementById("htmlTag").setAttribute("theme", this.theme);

      if (!this.token) {
        this.goToError(ConnectErrorCode.TokenExpired);
        return;
      }

      this.tokenService.loadTokenInformationIntoService(this.token);
    });

    this.pageTitleService.pageTitle.subscribe((udpatedPageTitle) => {

      this.pageTitle = udpatedPageTitle;
      this.cdref.detectChanges();
    });

    this.loadingService.isLoading.subscribe((isLoading) => {

      this.isLoading = isLoading;
      this.cdref.detectChanges();
    });
  }

  onClose(): void {

    // If it's terms and conditions we dont close the popup
    if (this.pageTitle && this.pageTitle == 'Terms and conditions') {
      this.termsConditionsService.updateDisplayTermsAggregation(false);
      return;
    }

    this.eventListenerService.closePopup();
    return;
  }

  goToError(erroCode: ConnectErrorCode): void {

    //this.router.navigate(['/error'], { queryParams: { type: erroCode } });
  }
}
